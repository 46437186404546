import { graphql, Link, PageProps } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import { PageProductQuery } from '../../graphql-types';
import Layout from '../components/layout';

const Product = ({ data }: PageProps<PageProductQuery>): JSX.Element => {
  if (!data.googlePlayBadgeImage) {
    throw new Error('file が取得できません。');
  }
  if (!data.googlePlayBadgeImage.childImageSharp) {
    throw new Error('childImageSharp が取得できません。');
  }
  if (!data.googlePlayBadgeImage.childImageSharp.fluid) {
    throw new Error('fluid が取得できません。');
  }
  const iconMap = new Map<string, FluidObject>();
  data.productImages.edges.forEach((edge) => {
    if (edge.node.parent?.relativePath) {
      iconMap.set(edge.node.parent.relativePath, edge.node.fluid);
    }
  });

  return (
    <Layout seo={{ title: 'Product' }}>
      <section className="section">
        <div className="container">
          <h1 className="title">Product</h1>

          <div className="tile is-ancestor">
            <div className="tile is-4 is-parent">
              {data.allMarkdownRemark.edges.map((edge) => {
                if (!edge.node.frontmatter) {
                  throw new Error('frontmatter が取得できません。');
                }
                if (!edge.node.parent) {
                  throw new Error('parent が取得できません。');
                }
                const { name, catchphrase, googlePlayUrl } = edge.node.frontmatter;
                const { relativeDirectory } = edge.node.parent;
                return (
                  <div className="tile is-child box">
                    <Link to={`./${relativeDirectory}/`}>
                      <h3 className="title">{name}</h3>

                      <Img
                        fluid={iconMap.get(`${relativeDirectory}/icon.png`)}
                        alt={`${name} アイコン`}
                      />
                    </Link>

                    <p>{catchphrase}</p>

                    <a href={googlePlayUrl ?? ''}>
                      <Img
                        className="mt-5"
                        fluid={data.googlePlayBadgeImage.childImageSharp.fluid}
                        alt="Google Play で手に入れよう"
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <p className="mt-5 is-size-7">Google Play および Google Play ロゴは、Google LLC の商標です。</p>
      </section>
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query PageProduct {
    allMarkdownRemark(filter: {fields: {sourceInstanceName: {eq: "product"}}}) {
      edges {
        node {
          frontmatter {
            name
            catchphrase
            googlePlayUrl
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
    googlePlayBadgeImage: file(relativePath: { eq: "google-play-badge.png" }) {
      childImageSharp {
        fluid(maxWidth: 128) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productImages: allImageSharp(filter: {fields: {sourceInstanceName: {eq: "product"}}}) {
      edges {
        node {
          id
          parent {
            ... on File {
              name
              relativePath
            }
          }
          fluid(maxWidth: 128) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }  
`;
